var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "container options-container" },
    [
      _c("v-data-table", {
        staticClass: "v-step-10 searchable sortable options-table",
        attrs: {
          "single-select": "",
          "item-key": "SystemType",
          headers: _vm.headers,
          items: _vm.items,
          "items-per-page": 30,
          "item-class": _vm.rowClass,
          "hide-default-footer": true,
          height: "42vh",
          "mobile-breakpoint": "0",
          "fixed-header": ""
        },
        on: { "click:row": _vm.rowClick },
        model: {
          value: _vm.selectedItem,
          callback: function($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
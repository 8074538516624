
















export default {
    data() {
        return {
            initLoadSel: false,
            selectedItem: [],
            headers: [
                {
                    text: 'System Type',
                    align: 'start',
                    sortable: true,
                    value: 'SystemType',
                    width: "44%",
                    padding: "10px",
                    fixed: true,
                    class: 'grey-header'
                },
                {
                    text: 'Bay Weight (kg/m²)', value: 'BayUnitWeight',
                    width: "12%",
                    align: 'end',
                    fixed: true,
                    class: 'grey-header'
                },
                {
                    text: 'Struct. Depth (mm)', value: 'OverallStructuralDepth',
                    width: "12%",
                    align: 'end',
                    fixed: true,
                    class: 'grey-header'
                },
                {
                    text: 'Cost ($/m²)', value: 'Cost',
                    width: "12%",
                    align: 'end',
                    fixed: true,
                    class: 'grey-header'
                },
                {
                    text: 'Carbon (kgCO₂/m²)', value: 'Carbon',
                    width: "15%",
                    align: 'end',
                    fixed: true,
                    class: 'grey-header'
                },
            ],
            items: [],
            itemPrefix: [
                {
                    'BayUnitWeight': '',
                    'OverallStructuralDepth': '',
                    'Cost': '$ ',
                    'Carbon': '',
                }
            ],
            itemSuffix: [
                {
                    'BayUnitWeight': 'kg/m²',
                    'OverallStructuralDepth': 'mm',
                    'Cost': '',
                    'Carbon': 'kgCO₂/m²',
                }
            ],
            itemComma: [
                {
                    'BayUnitWeight': true,
                    'OverallStructuralDepth': true,
                    'Cost': true,
                    'Carbon': true,
                }
            ],
        }
    },
    created() {
        this.$root.$on('refreshOptionSummary', this.refreshOptionSummary);
    },
    watch: {
        'items'(val) {               
            if (val && val.length > 0 && !this.initLoadSel) {
                this.selectTimberRow(this.$store.state.materials.timberTypeSelection);                    
            }
        },
        '$store.state.materials.timberTypeSelection'(val) {
            if (!this.items || this.items.length === 0) {
                this.initLoadSel = false;
                return;
            }
            this.selectTimberRow(val);
        },
    },
    methods: {
        selectTimberRow(val) {
            if (val && val !== this.selectedItem?.SystemType) {
                this.selectedItem = [];
                this.selectedItem.push(this.items.find(i => i.SystemType === val));
                this.initLoadSel = true;
            }
        },
        formatCost(value) {
            if (!value) { return; }
            return value.toString().replace('.00', '')
        },
        getRankingColor(item, rankingItem) {
            var rankingColor = 'white';
            var rankingValue = 0
            switch (rankingItem) {
                case 'BayUnitWeight':
                    rankingValue = item.ranking_BayUnitWeight;
                    break;
                case 'OverallStructuralDepth':
                    rankingValue = item.ranking_OverallStructuralDepth;
                    break;
                case 'Cost':
                    rankingValue = item.ranking_Cost;
                    break;
                case 'Carbon':
                    rankingValue = item.ranking_Carbon;
                    break;
            }
            switch (rankingValue) {
                case 1:
                    rankingColor = 'yellow';
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                    rankingColor = 'white';
                    break;
                case 11:
                    rankingColor = 'red';
                    break;
            }
            return rankingColor;
        },
        rowClick(item, row) {
            if (item.MaterialType !== 'Timber') { return; }
            this.selectedItem = [];
            this.selectedItem.push(item);
            this.$store.state.materials.optionSystemTypeSelected = item.SystemType;
            row.select(true);
        },
        rowClass(item) {
            var className = '';
            switch (item.MaterialType) {
                case 'Timber':
                    className = "timberRow";
                    break;
                case 'Concrete':
                    className = "concreteRow";
                    break;
                case 'Steel':
                    className = "steelRow";
                    break;
            }
            return className;
        },
        async refreshOptionSummary() {
            try {
                await Promise.resolve(this.items = this.$store.getters['materials/getOptionSummary']);

            } catch (error) {
            } finally {
            }
        }
    }
}
